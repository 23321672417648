import { graphql, useStaticQuery } from 'gatsby';
import { AboutPage } from '~/config';
import { frontmatterWithSlug } from '~/utils';

export const useAboutPageQuery = () => {
  const data = useStaticQuery(graphql`
    {
      pages: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/pages/about/" } }
      ) {
        edges {
          node {
            frontmatter {
              heading
              name
              content
              about_image {
                image_alt
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const nodes = frontmatterWithSlug<Partial<AboutPage>>(data.pages);

  return { nodes };
};
