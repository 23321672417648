import { PageProps } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { FC, useMemo } from 'react';
import { isMobileOnly } from 'react-device-detect';
import ReactMarkdown from 'react-markdown';
import tw, { styled, theme } from 'twin.macro';
import {
  headingCss,
  Highlight,
  highlightProps,
  LinkPlus,
  PageColumn,
  PageContainer,
} from '~/components/common';
import { Layout } from '~/components/common/layout/Layout';
import { AutumnalRainbow } from '~/components/home/home-content';
import { SEO } from '~/components/SEO';
import { AboutPage, Frontmatter } from '~/config';
import { useAboutPageQuery } from '~/hooks/useAboutPageQuery';

// don't add export - uses local gatsby plugin
// eslint-disable-next-line
const frontmatter: Frontmatter = {
  title: 'About',
  order: 2,
  screenShoot: false,
};

const Page: FC<PageProps> = (props) => {
  const { nodes } = useAboutPageQuery();
  const about = nodes.find((n) => n.name === 'about')! as AboutPage;
  const image =
    about.about_image?.image.childImageSharp &&
    getImage(about.about_image.image.childImageSharp.gatsbyImageData);
  const imageAlt = about.about_image?.image_alt || '';

  // memoized to prevent re-rendering
  const markdownConfig = useMemo(
    () => ({
      p: (props) => <P>{props.children}</P>,
      // TODO: animate black boxes and remove set width
      code: (props) => <BlackBox>{props.children}</BlackBox>,
      a: (props) => (
        <LinkPlus
          {...props}
          to={props.href}
          customCss={tw`underline hover:(no-underline) text-brand-gray`}
        >
          {props.children}
        </LinkPlus>
      ),
    }),
    [],
  );

  const headingConfig = useMemo(
    () => ({
      p: (props) => <H2>{props.children}</H2>,
    }),
    [],
  );

  const description = about.content.split('\n')?.[0];

  const headingJsx = (
    <Highlight
      {...highlightProps}
      bgColor={theme`colors.brand-coral`}
      customCss={[highlightProps.customCss, tw`md:(ml-5)`]}
    >
      <ReactMarkdown components={headingConfig}>{about.heading}</ReactMarkdown>
    </Highlight>
  );

  return (
    <Layout footer>
      <SEO title={frontmatter.title} description={description} path="/about" />
      <PageContainer>
        <PageColumn>
          {isMobileOnly ? (
            <>
              <TopContainer>
                <PhotoContainer>
                  {image && <GatsbyImage image={image} alt={imageAlt} />}
                  <AutumnalRainbow />
                </PhotoContainer>

                {headingJsx}
              </TopContainer>
              <ContentSection>
                <ReactMarkdown components={markdownConfig}>
                  {about.content}
                </ReactMarkdown>
              </ContentSection>
            </>
          ) : (
            <>
              <PhotoContainer>
                {image && <GatsbyImage image={image} alt={imageAlt} />}
                <AutumnalRainbow />
              </PhotoContainer>
              <div>
                {headingJsx}

                <ContentSection>
                  <ReactMarkdown components={markdownConfig}>
                    {about.content}
                  </ReactMarkdown>
                </ContentSection>
              </div>
            </>
          )}
        </PageColumn>
      </PageContainer>
    </Layout>
  );
};
const TopContainer = tw.div`flex items-start`;
const PhotoContainer = tw.div`[min-width:180px] [max-width:150px] mx-auto pr-3 sm:[max-width:320px] lg:[min-width:320px]`;
const ContentSection = tw.section`py-3 md:(px-6 py-0 my-0)`;
export const P = tw.p`mb-4`;
const H2 = styled.h2(() => [headingCss]);
const BlackBox = tw.span`inline-block bg-black h-4 leading-6 whitespace-pre [vertical-align:sub] [width:200px]`;

export default Page;
